/* eslint-disable global-require */
import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
import { paginateArray } from '@/helpers/utils'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'
import router from '@/router'

export default function useOperations() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()

  const propriete = ref([])
  const total = ref(null)
  const loader = ref(false)
  const proprieteProcess = ref(false)
  const contratProcess = ref(false)
  const contratSuccess = ref(false)
  const proprieteSuccess = ref(false)
  const partieCommuneProcessCloseModal = ref(false)
  const partieCommuneProcess = ref(false)
  const categoryFraisSuccess = ref(false)

  const proprietes = ref([])
  const fraisCategories = ref([])
  const listeTypePartieCommune = ref([])
  const listeTacheEntretiens = ref([])
  const listePartieCommune = ref([])
  const listePlanificationEntretiens = ref([])
  const proprietesDataPaginate = ref([])
  const contractTypes = ref([])
  const factureContratSimples = ref([])
  const demande = ref([])
  const contratsSimples = ref([])
  const errors = ref('')

  
  const getAllTypesPartiesCommunes = async id => {
    try {
      loader.value = true
      partieCommuneProcess.value = true

      const response = await axiosClient.get(`/get-all-type-partie-communes/${id}`)
      if (response.data.success === true) {
        loader.value = false
        partieCommuneProcess.value = false

        listeTypePartieCommune.value = response.data.data
      }
    } catch (error) {
      loader.value = false
      partieCommuneProcess.value = false

    }
  }
  const getAllTypesTaches = async () => {
    try {
      loader.value = true
      partieCommuneProcess.value = true

      const response = await axiosClient.get('/get-all-type-tache-entretiens')
      if (response.data.success === true) {
        loader.value = false
        partieCommuneProcess.value = false

        listeTypePartieCommune.value = response.data.data
      }
    } catch (error) {
      loader.value = false
      partieCommuneProcess.value = false

    }
  }
  
  const getAllPartiesCommunes = async id => {
    try {
      loader.value = true
      partieCommuneProcess.value = true

      const response = await axiosClient.get(`/get-all-partie-communes/${id}`)
      if (response.data.success === true) {
        loader.value = false
        partieCommuneProcess.value = false

        listePartieCommune.value = response.data.data
      }
    } catch (error) {
      loader.value = false
      partieCommuneProcess.value = false

    }
  }
  const getAllTachesEntretienByCoproprieteId = async id => {
    try {
      loader.value = true
      partieCommuneProcess.value = true

      const response = await axiosClient.get(`/get-all-tache-entretiens/${id}`)
      if (response.data.success === true) {
        loader.value = false
        partieCommuneProcess.value = false

        listePlanificationEntretiens.value = response.data.data
      }
    } catch (error) {
      loader.value = false
      partieCommuneProcess.value = false

    }
  }
  const getAllTacheEntretienByCoproprieteId = async id => {
    try {
      loader.value = true
      partieCommuneProcess.value = true

      const response = await axiosClient.get(`/get-all-tache-entretiens/${id}`)
      if (response.data.success === true) {
        loader.value = false
        partieCommuneProcess.value = false

        listeTacheEntretiens.value = response.data.data
      }
    } catch (error) {
      loader.value = false
      partieCommuneProcess.value = false

    }
  }

  const handleTypePartieCommune = async data => {
    try {
      errors.value = ''
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-type-partie-commune', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        categoryFraisSuccess.value = true
        partieCommuneProcessCloseModal.value = true

      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      categoryFraisSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const handleTypeTaches = async data => {
    try {
      errors.value = ''
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-type-tache-entretien', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        categoryFraisSuccess.value = true
        partieCommuneProcessCloseModal.value = true

        await getAllTypesTaches()
      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      categoryFraisSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  
  const handleUpdateTypeTaches = async data => {

    try {
      errors.value = ''
      partieCommuneProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-type-tache-entretien/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        partieCommuneProcessCloseModal.value = true
        await getAllTypesTaches()
      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const handleTacheEntretien = async data => {
    try {
      errors.value = ''
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-planification-entretien', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        categoryFraisSuccess.value = true
        partieCommuneProcessCloseModal.value = true
      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      categoryFraisSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const handleTacheDEntretienParType = async data => {
    try {
      errors.value = ''
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-tache-entretien', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        categoryFraisSuccess.value = true
        partieCommuneProcessCloseModal.value = true
      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      categoryFraisSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  
  const handleUpdateTacheDEntretienParType = async data => {

    try {
      errors.value = ''
      partieCommuneProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-tache-entretien/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        partieCommuneProcessCloseModal.value = true
      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const handleUpdateTacheEntretien = async data => {

    try {
      errors.value = ''
      partieCommuneProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-planification-entretien/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        partieCommuneProcessCloseModal.value = true
      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const handlePartieCommune = async data => {
    try {
      errors.value = ''
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-partie-commune', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        categoryFraisSuccess.value = true
        partieCommuneProcessCloseModal.value = true
      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      categoryFraisSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }


  const handleUpdateTypePartieCommune = async data => {

    try {
      errors.value = ''
      partieCommuneProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-type-partie-commune/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        partieCommuneProcessCloseModal.value = true
      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const handleChangeTaskStatus = async data => {
    console.log(data);
    try {
      errors.value = ''
      partieCommuneProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/change-task-entretien-status/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        partieCommuneProcessCloseModal.value = true
      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const handleUpdatePartieCommune = async data => {

    try {
      errors.value = ''
      partieCommuneProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-partie-commune/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        partieCommuneProcessCloseModal.value = true
      }
    } catch (error) {
      partieCommuneProcessCloseModal.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  const getPlanificationEntretienByCoproprieteId = async id => {
    try {
      loader.value = true
      partieCommuneProcess.value = true

      const response = await axiosClient.get(`/get-all-planification-entretiens/${id}`)
      if (response.data.success === true) {
        loader.value = false
        partieCommuneProcess.value = false

        listePlanificationEntretiens.value = response.data.data
      }
    } catch (error) {
      loader.value = false
      partieCommuneProcess.value = false

    }
  }
  const getPlanificationEntretienNotEnAttenteByCoproprieteId = async id => {
    try {
      loader.value = true
      partieCommuneProcess.value = true

      const response = await axiosClient.get(`/get-planification-entretiens-not-en-attente/${id}`)
      if (response.data.success === true) {
        loader.value = false
        partieCommuneProcess.value = false

        listePlanificationEntretiens.value = response.data.data
      }
    } catch (error) {
      loader.value = false
      partieCommuneProcess.value = false

    }
  }

  return {
    proprietesDataPaginate,
    errors,
    propriete,
    proprietes,
    proprieteProcess,
    loader,
    proprieteSuccess,


    handleTypePartieCommune,
    getAllTypesPartiesCommunes,
    listeTypePartieCommune,
    partieCommuneProcess,
    getAllPartiesCommunes,
    handlePartieCommune,
    listePartieCommune,
    partieCommuneProcessCloseModal,
    handleUpdateTypePartieCommune,
    handleUpdatePartieCommune,
    getPlanificationEntretienByCoproprieteId,
    listePlanificationEntretiens,
    getAllTacheEntretienByCoproprieteId,
    listeTacheEntretiens,
    handleTacheEntretien,
    handleUpdateTacheEntretien,
    getAllTypesTaches,
    handleTypeTaches,
    handleUpdateTypeTaches,
    getAllTachesEntretienByCoproprieteId,
    handleTacheDEntretienParType,
    handleUpdateTacheDEntretienParType,
    getPlanificationEntretienNotEnAttenteByCoproprieteId,
    handleChangeTaskStatus,
  }
}
